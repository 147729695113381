import { LivePrice } from "@prisma/client"

import { BaseLive } from "../server/dao/live_dao"
import { printMoney } from "./currency_util"
import { sumNumbers } from "./number_util"

export const isString = (value: any): value is string => typeof value === "string"

export const removeAlphaNumericCharacters = (str: string) => str.replace(/[^a-zA-Z ]/g, "")

export const formatUsername = (username: string) =>
    username
        .replace(/[^a-zA-Z0-9]/g, "")
        .replace(/\s/g, "")
        .toLowerCase()

export const capitalizeFirstLetter = (str?: string) =>
    str ? str.charAt(0).toUpperCase() + str.slice(1) : str

export const formatFullName = (user: { firstName?: string | null; lastName?: string | null }) =>
    user.firstName && user.lastName ? `${user.firstName} ${user.lastName}` : ""

export const formatLivePriceForAnalytics = (live: BaseLive) => {
    const { price } = live
    return `${formatPriceForDisplayValue(price!)}`
}

export const formatPriceForDisplayValue = (price: LivePrice) => {
    const isHigherDenomCurrency = ["USD", "GBP"].includes(price.currency)
    const isDivisible = (price?.totalInLowestDenom ?? 0) > 0
    const shouldRaiseDenom = isHigherDenomCurrency && isDivisible

    return shouldRaiseDenom ? price.totalInLowestDenom / 100 : 0
}

export const formatValueForCurrency = (value: string, currency: string) => {
    const number = parseInt(value)
    return printMoney({ total: number, options: { currency } })
}

export const getCharCountFromObject = (value: any): number => {
    if (typeof value === "string") {
        return value.length
    }
    if (typeof value === "object" && value.constructor.name === "Object") {
        return sumNumbers(Object.values(value).map((v) => getCharCountFromObject(v)))
    }
    if (Array.isArray(value)) {
        return sumNumbers(value.map((v) => getCharCountFromObject(v)))
    }
    return 0
}

// converts time in seconds to human readable hours/minutes/seconds string
export const secondsToHms = (
    d: number,
    abbreviate: { minutes: boolean; hours: boolean },
): string => {
    d = Number(d)
    const h = Math.floor(d / 3600)
    const m = Math.floor((d % 3600) / 60)

    const hourStr = abbreviate?.hours ? "hr" : "hour"
    const minuteStr = abbreviate?.minutes ? "m" : "min"

    const hDisplay = h > 0 ? h + (h == 1 ? ` ${hourStr} ` : ` ${hourStr}s `) : ""
    const mDisplay =
        m > 0
            ? m + (m == 1 ? ` ${minuteStr}` : ` ${minuteStr}${abbreviate?.minutes ? "" : "s"}`)
            : ""

    return hDisplay + mDisplay
}

export const formatDurationToTimestamp = (durationSeconds: number) => {
    const hours = Math.floor(durationSeconds / 3600)
    const minutes = Math.floor((durationSeconds % 3600) / 60)
    const seconds = durationSeconds % 60

    const pad = (num: number) => (num < 10 ? "0" + num : num.toString())

    return hours > 0
        ? `${pad(hours)}:${pad(minutes)}:${pad(seconds)}`
        : `${pad(minutes)}:${pad(seconds)}`
}

export const formatTimeForClipSeek = (time: number) => {
    const minutes = Math.floor(time / 60)
    const seconds = Math.floor(time % 60)
    return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`
}

export const cleanIdentifier = (str: string) => str?.toLowerCase().trim()

export const trimStringToXChars = (str: string, maxChars: number) => {
    return str.length < maxChars ? str : `${str.substring(0, maxChars)}...`
}

export const containsSpecialChars = (str: string) => {
    const specialChars = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/
    return specialChars.test(str)
}

export const containsSubstrings = (mainString: string, substrings: string[]) => {
    return substrings.some((substring) => mainString.includes(substring))
}

export const isValidUrl = (url: string) => {
    const pattern = new RegExp(
        "^(https?:\\/\\/)?" + // protocol
            "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
            "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
            "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
            "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
            "(\\#[-a-z\\d_]*)?$",
        "i",
    ) // fragment locator

    return !!pattern.test(url)
}

export const cleanTextForFilename = (input: string) => {
    const reservedFileSystemNames = /^(con|prn|aux|nul|com[0-9]|lpt[0-9])(\..*)?$/i
    const leadingTrailingDots = /^\.+|\.+$/g
    const spaces = /\s/g
    const nonAlphaNumeric = /[^a-zA-Z0-9\- ]/g
    return input
        .toLowerCase()
        .replace(nonAlphaNumeric, "")
        .replace(reservedFileSystemNames, "")
        .replace(leadingTrailingDots, "")
        .replace(spaces, "-")
        .trim()
        .slice(0, 100)
}

const SI_SYMBOL = ["", "k", "M", "G", "T", "P", "E"]

export const abbreviateNum = (number: number) => {
    if (number === 0) {
        return 0
    }

    // Thanks to Waylon Flinn

    // what tier? (determines SI symbol)
    // @ts-ignore
    const tier = (Math.log10(number) / 3) | 0 // eslint-disable-line

    // if zero, we don't need a suffix
    if (tier === 0) {
        return number
    }

    // get suffix and determine scale
    const suffix = SI_SYMBOL[tier]
    const scale = Math.pow(10, tier * 3)

    // scale the number
    let scaled: string | number = number / scale

    // format number
    scaled = scaled.toFixed(1)

    // with suffix
    return `${scaled}`.replace(".0", "") + suffix
}

export const getAlphabetic = (str: string): string => {
    return str.replace(/[^a-zA-Z]/g, "")
}

export const startsWithCapital = (word: string) => {
    return /^[A-Z]/.test(word)
}

export const formatDurationToMinutesSeconds = (duration: number) => {
    const minutes = Math.floor(duration / 60)
    const remainingSeconds = Math.floor(duration) % 60
    if (minutes === 0) {
        return `${remainingSeconds}s`
    }
    if (remainingSeconds === 0 && minutes > 0) {
        return `${minutes}m`
    }
    return `${minutes}m ${remainingSeconds}s`
}
