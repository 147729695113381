import type { NextRouter } from "next/router"
import { UAParser } from "ua-parser-js"

import _c from "../configs/constants"

export const isServer = !(
    typeof window !== "undefined" &&
    window.document &&
    // @ts-ignore
    window.document.createElement &&
    // @ts-ignore
    !!process?.browser
)

const MOBILE_BROWSER_REGEX =
    /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i
export const isMobileBrowser = () => {
    if (!navigator || !window) {
        return false
    }
    // @ts-ignore - userAgentData not defined on navigator
    if (!!navigator.userAgentData) {
        // @ts-ignore
        return navigator.userAgentData.mobile
    }
    // @ts-ignore - window.opera is not defined in type definition
    const userAgent = navigator.userAgent ?? navigator.vendor ?? window.opera
    return MOBILE_BROWSER_REGEX.test(userAgent)
}

export const isUserAgentMobile = (userAgent?: string) => {
    if (!userAgent) {
        return false
    }
    return /iPhone|iPad|iPod|Android/i.test(userAgent)
}

export const isRobot = () => {
    return !!(
        !isServer &&
        (navigator.userAgent.includes("Googlebot") ||
            navigator.userAgent.includes("Lighthouse") ||
            navigator.userAgent.includes("Yahoo!") ||
            navigator.userAgent.includes("Ahrefs") ||
            navigator.userAgent.includes("Baidu") ||
            /bot|crawler|spider|crawling/i.test(navigator.userAgent))
    )
}

export const isTrackableEnvironment = () => !!(_c.isProduction && !isServer && !isRobot())

export const removeTrailingSlash = (str: string) => {
    if (str.endsWith("/")) {
        return str.slice(0, -1)
    }
    return str
}

export const getDeviceOs = (userAgent?: string) => {
    let parser
    if (userAgent) {
        parser = new UAParser(userAgent)
    } else {
        parser = new UAParser()
    }
    return parser.getOS().name! ?? "unknown"
}

export const isMobileSafari = () => {
    const browserName = new UAParser().getBrowser().name
    const casedName = browserName?.toLowerCase() ?? "unknown"
    return casedName.includes("mobile") && casedName.includes("safari")
}

export const getPageSource = (sourceRaw: string) => {
    let source
    if (!sourceRaw) {
        source = "direct"
    } else {
        source = extractSourceFromUrl(sourceRaw)
    }
    return source ?? undefined
}

export const getWebsiteHost = (url: string): string | null => {
    if (!url) {
        return null
    }

    try {
        const parsedUrl = new URL(url)
        return parsedUrl.hostname
    } catch (error) {
        return null
    }
}

const SOURCE_FIRST_REGEX = /(podcasts\.google|music\.amazon|player\.fm|beacons\.ai|linktr\.ee)/
const SOURCE_SECOND_REGEX =
    /(tiktok|youtube|snapchat|google|facebook|instagram|twitch|yahoo|twitter|linkedin|livelink|spotify)/

export const extractSourceFromUrl = (url: string): string | undefined => {
    if (url.includes("com.google.android.gm")) {
        return "gmail"
    } else if (url.includes("org.telegram.messenger")) {
        return "telegram"
    }

    const hostname = getWebsiteHost(url)
    if (hostname) {
        const firstMatch = hostname.match(SOURCE_FIRST_REGEX) ?? []
        if (firstMatch[0]) {
            return firstMatch[0]
        }

        const secondMatch = hostname.match(SOURCE_SECOND_REGEX) ?? []
        if (secondMatch[0]) {
            return secondMatch[0]
        }

        if (hostname === "t.co") {
            return "twitter"
        }
    }

    return undefined
}

export const removeParamsFromUrl = (router: NextRouter) =>
    router.replace(router.asPath.split("?")[0], undefined, { shallow: true })

export const measureTextDimensions = (
    args: { text: string; fontSize: string; fontFamily: string },
    containerWidth = "1000px",
) => {
    const { text, fontSize, fontFamily } = args
    const container = document.createElement("div")
    const span = document.createElement("span")

    container.style.position = "absolute"
    container.style.width = containerWidth
    container.style.left = "-9999px"
    container.style.top = "-9999px"

    span.innerText = text
    span.style.fontFamily = fontFamily
    span.style.fontSize = fontSize

    container.appendChild(span)
    document.body.appendChild(container)

    // Get the width and height
    const rect = container.getBoundingClientRect()

    // Cleanup
    document.body.removeChild(container)

    // Return the width and height
    return { width: rect.width, height: rect.height }
}
