import routes from "../configs/routes"
import { IPostCreateUserBody, TPostCreateUserResponse } from "../pages/api/v1/oauth/create-user"
import { IPostRevokeUserBody, IPostRevokeUserResponse } from "../pages/api/v1/oauth/revoke-user"
import { IGetAllSchedulesResponse } from "../pages/api/v1/social-platform/all-schedules"
import {
    IPostCancelScheduleBody,
    IPostCancelScheduleResponse,
} from "../pages/api/v1/social-platform/cancel-post"
import {
    IGetCreatorInfoPayload,
    TGetCreatorInfoResponse,
} from "../pages/api/v1/social-platform/creator"
import { TGetScheduleInfoResponse } from "../pages/api/v1/social-platform/schedule-info"
import {
    IPostCreateScheduleBody,
    IPostCreateScheduleResponse,
} from "../pages/api/v1/social-platform/schedule-post"
import {
    IGetScheduleStatusQueryParams,
    TGetScheduleStatusResponse,
} from "../pages/api/v1/social-platform/schedule-status"
import {
    IGetScheduleStatusInRangeQueryParams,
    TGetScheduleStatusInRangeResponse,
} from "../pages/api/v1/social-platform/schedule-status-in-range"
import {
    IGetSchedulesQueryParams,
    TGetScheduleResponse,
} from "../pages/api/v1/social-platform/schedules"
import { handleRequest } from "../utils/network_util"

const socialPlatformService = (hr = handleRequest) => ({
    getScheduleStatus: (
        payload: IGetScheduleStatusQueryParams,
    ): Promise<TGetScheduleStatusResponse> =>
        hr(routes.GET_SOCIAL_PLATFORM_SCHEDULE_STATUS(payload.taskId)),
    getAllSchedules: (): Promise<IGetAllSchedulesResponse> =>
        hr(routes.GET_SOCIAL_PLATFORM_ALL_SCHEDULES),
    getScheduleInfo: (
        timeStamp: number,
        taskId: string[],
        snippetIds: string[],
    ): Promise<TGetScheduleInfoResponse> =>
        hr(routes.GET_SCHEDULE_INFO(timeStamp, taskId, snippetIds)),
    getScheduleStatusInRange: (
        query: IGetScheduleStatusInRangeQueryParams,
    ): Promise<TGetScheduleStatusInRangeResponse> =>
        hr(routes.GET_SOCIAL_PLATFORM_SCHEDULE_STATUS_IN_RANGE(query.start, query.end)),
    getSchedules: <T extends TGetScheduleResponse>(query: IGetSchedulesQueryParams): Promise<T> =>
        hr(routes.GET_SOCIAL_PLATFORM_SCHEDULE_POST(query.level)),
    getCreatorInfo: (payload: IGetCreatorInfoPayload[]): Promise<TGetCreatorInfoResponse> =>
        hr(routes.GET_SOCIAL_PLATFORM_CREATOR_INFO(JSON.stringify(payload))),
    postCancelSchedule: (body: IPostCancelScheduleBody): Promise<IPostCancelScheduleResponse> =>
        hr(routes.POST_SOCIAL_PLATFORM_CANCEL_POST, body),
    postCreateSchedule: (body: IPostCreateScheduleBody): Promise<IPostCreateScheduleResponse> =>
        hr(routes.POST_SOCIAL_PLATFORM_SCHEDULE_POST, body),
    postCreateUser: (body: IPostCreateUserBody): Promise<TPostCreateUserResponse> =>
        hr(routes.POST_OAUTH_SOCIAL_PLATFORM_CREATE_USER, body),
    postRevokeUser: (body: IPostRevokeUserBody): Promise<IPostRevokeUserResponse> =>
        hr(routes.POST_OAUTH_SOCIAL_PLATFORM_REVOKE_USER, body),
})

export default socialPlatformService
